@import "styles/main.scss";

a {
  text-decoration: none;
}

.button-wrapper {
  border-radius: 999px;
  background-color: $button-primary;
  cursor: pointer;
  transition: 0.3s all;
  border: none;
  width: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Euclid Circular A";

  &--default {
    padding: $space-6 $space-10;
    font-size: 2.4rem;
    font-weight: 600;
    color: $text-black;
  }

  &--small {
    padding: $space-3 $space-5;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &--background-black {
    background-color: $background-2;
    color: $text-white;

    &:hover {
      background-color: $button-hover-2 !important;
      color: $text-white !important;
    }
  }

  &:hover {
    background-color: $button-hover;
  }
}
