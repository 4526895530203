@import "styles/main.scss";

.header-wrapper {
  width: 100%;
  padding: $space-5 0;
  border-bottom: 1px solid rgba($color: $divider-secondary, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  background-color: $background-white;
  z-index: 999;

  .header-container {
    @include container;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-logo {
      display: flex;
      align-items: center;
      gap: $space-2;
      font-weight: 600;
      font-size: 2rem;

      .icon-logo {
        font-size: 4rem;
        color: $logo-primary;
      }
    }

    .header-right {
      display: flex;
      align-items: center;

      @include responsive(normal) {
        gap: $space-8;
      }

      .box-text {
        display: flex;
        align-items: center;
        gap: $space-6;
        white-space: nowrap;

        a {
          color: $text-primary;
          text-decoration: none;
        }

        .icon-social {
          font-size: 3.2rem;
          cursor: pointer;
          transition: 0.3s all;

          @include responsive(normal) {
            font-size: 2.6rem;
          }

          &:hover {
            color: $logo-primary;
          }
        }
      }

      .vertical-line {
        height: 2rem;
        width: 1px;
        background-color: $divider-secondary;
        display: none;

        @include responsive(normal) {
          display: block;
        }
      }

      .button-header {
        display: none;

        @include responsive(normal) {
          display: block;
        }
      }
    }
  }
}
