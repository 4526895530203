@font-face {
  font-family: "Euclid Circular A";
  src: url("../assets/fonts/Euclid-Circular-A-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("../assets/fonts/Euclid-Circular-A-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("../assets/fonts/Euclid-Circular-A-Bold.ttf");
  font-weight: 600;
}
