$background: #000000;
$background-2: #282828;
$background-child: #0f0f0f;
$background-white: #ffffff;
$background-white-2: #f5f5f5;

$logo-primary: #f0c43f;
$logo-secondary: #ffffff;

$button-primary: #f0c43f;
$button-hover: #f6d883;
$button-hover-2: #7a7a7a;

$divider: #1a1a1a;
$divider-secondary: #cccccc;

$text-hint: #a3a3a3;
$text-primary: #282828;
$text-secondary: #525252;
$text-black: #000000;

$text-white: #ffffff;
// #CCCCCC
