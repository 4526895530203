h1 {
  font-size: 4rem;
  font-weight: 600;

  @include responsive(tablet) {
    font-size: 6.4rem;
  }
}

h2 {
  font-size: 5.6rem;
  font-weight: 600;
}

h3 {
  font-size: 4.8rem;
  font-weight: 600;
}

h4 {
  font-size: 4rem;
  font-weight: 600;
}

h5 {
  font-size: 2.4rem;
  font-weight: 600;

  @include responsive(tablet) {
    font-size: 3.2rem;
  }
}

h6 {
  font-size: 2.4rem;
  font-weight: 600;
}

.text-lg {
  font-size: 2.8rem;
}

.text-normal {
  font-size: 2.4rem;
}

.text-base {
  font-size: 1.6rem;

  @include responsive(tablet) {
    font-size: 2rem;
  }
}

.text-sm {
  font-size: 1.6rem;

  @include responsive(tablet) {
    font-size: 1.8rem;
  }
}

.text-xsm {
  font-size: 1.6rem;
}
