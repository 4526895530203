$mobile: 576px;
$tablet: 600px;
$tablet-2: 820px;
$small: 1024px;
$normal: 1280px;
$mac-13: 1440px;
$mac-16: 1600px;
$fhd: 1920px;
$screen2k: 2000px;
$screen4k: 3800px;

@mixin responsive($canvas) {
  @if $canvas==mobile {
    @media only screen and (min-width: $mobile) {
      @content;
    }
  } @else if $canvas==tablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  } @else if $canvas==tablet-2 {
    @media only screen and (min-width: $tablet-2) {
      @content;
    }
  } @else if $canvas==small {
    @media only screen and (min-width: $small) {
      @content;
    }
  } @else if $canvas==normal {
    @media only screen and (min-width: $normal) {
      @content;
    }
  } @else if $canvas==mac-13 {
    @media only screen and (min-width: $mac-13) {
      @content;
    }
  } @else if $canvas==mac-16 {
    @media only screen and (min-width: $mac-16) {
      @content;
    }
  } @else if $canvas==fhd {
    @media only screen and (min-width: $fhd) {
      @content;
    }
  } @else if $canvas==screen2k {
    @media only screen and (min-width: $screen2k) {
      @content;
    }
  } @else if $canvas==screen4k {
    @media only screen and (min-width: $screen4k) {
      @content;
    }
  }
}

@mixin container {
  max-width: 100%;
  padding: 0 $space-5;
  margin: 0 auto;

  @include responsive(tablet) {
    padding: 0 $space-10;
  }

  @include responsive(small) {
    max-width: 100%;
    padding: 0 $space-16;
  }

  @include responsive(mac-13) {
    max-width: 1400px;
    // max-width: unset;
    // width: 1400px;
  }
}

@mixin container-fluid {
  max-width: 100%;
  padding: 0 $space-4;
  margin: 0 auto;

  @include responsive(tablet) {
    padding: 0 $space-8;
  }

  @include responsive(mac-13) {
    width: 100%;
    padding: 0 $space-12;
  }

  @include responsive(fhd) {
    width: 1920px;
    padding: 0 $space-12;
  }
}
