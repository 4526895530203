@import "./main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Euclid Circular A";
  color: $text-primary;

  // @include responsive(normal) {
  //   overflow-x: unset;
  // }
}

html {
  // min-height: 100%;
  // height: auto;
  // font-size: 56% !important;
  font-size: 10px;
  width: 100%;
  font-size: 10px;
  scrollbar-width: thin;
  font-weight: 400;

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive(mac-13) {
    font-size: 62.5% !important;
  }
}

body {
  width: 100%;
  // overflow: hidden;
  font-size: 1.6rem;
  line-height: unset;

  @include responsive(tablet) {
    font-size: 2rem;
  }
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a {
  line-height: 0;
}
