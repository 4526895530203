.content-wrapper {
  .section-wrapper {
    padding: $space-10 0;

    @include responsive(tablet) {
      padding: 12rem 0;
    }

    &--it-work {
      background-color: rgba($logo-primary, 0.04);
    }

    &--hidden {
      overflow: hidden;
    }

    &--faq {
      background-color: $background-white-2;
      position: relative;
      z-index: 0;
      min-height: 90rem;
      padding-bottom: 0;

      @include responsive(normal) {
        min-height: 80rem;
      }
    }

    &--description {
      background-color: $logo-primary;
      color: $text-primary;
    }
  }

  .section-banner {
    @include container;
    height: 95rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 12rem !important;
    position: relative;
    z-index: 0;
    margin-bottom: $space-20;

    @include responsive(tablet) {
      margin-bottom: 0;
      height: unset;
    }

    @include responsive(normal) {
      padding-top: 16rem !important;
    }

    .icon-logo-banner {
      font-size: 46rem;
      width: 100%;
      z-index: -1;
      color: $logo-primary;
      display: none;

      @include responsive(tablet) {
        margin-top: $space-16;
        display: block;
        width: 200%;
      }

      @include responsive(normal) {
        width: 100%;
        margin-top: $space-16;
        font-size: 50rem;
      }

      .bg-image {
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20rem;
        background: linear-gradient(to top, $background-white 60%, rgba(#ffffff, 0));
      }
    }

    .icon-logo-banner-mobile {
      font-size: 46rem;
      width: 300%;
      z-index: -1;
      position: absolute;
      top: 12rem;
      left: 50%;
      transform: translateX(-50%);
      color: $logo-primary;

      @include responsive(tablet) {
        display: none;
      }

      .bg-image {
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20rem;
        background: linear-gradient(to top, $background-white 60%, rgba(#ffffff, 0));
      }
    }

    .banner-text {
      text-align: center;

      &--title {
        width: 100%;
        margin-bottom: $space-6;

        @include responsive(normal) {
          margin-bottom: $space-8;
          width: 90rem;
        }

        span {
          color: $logo-primary;
        }
      }

      &--description {
        margin-bottom: $space-12;
        width: 100%;
        color: $text-secondary;

        @include responsive(normal) {
          margin-bottom: 5.6rem;
          width: 80rem;
        }
      }
    }

    .banner-button {
      width: calc(100vw - $space-5 * 2);

      @include responsive(normal) {
        width: unset;
        min-width: 40rem;
      }
    }
  }

  .section-info {
    @include container;

    .info-box {
      display: flex;
      gap: $space-5;
      margin-bottom: $space-5;
      flex-direction: column;

      @include responsive(tablet) {
        flex-direction: row;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .box-item {
        padding: $space-5;
        border-radius: 4rem;
        // background: linear-gradient(144deg, rgba(255, 255, 255, 0) 0%, #fafafa 100%);
        overflow: hidden;
        background-color: rgba(240, 196, 63, 0.08);

        @include responsive(tablet) {
          width: 50%;
        }

        @include responsive(normal) {
          width: 45%;
          padding: $space-10;

          &--big {
            width: 65%;
          }
        }

        &--big {
          background-color: rgba(240, 196, 63, 0.04);
        }

        &--img {
          width: 12rem;
          height: 12rem;
          border-radius: 50%;
          margin-bottom: $space-5;

          @include responsive(normal) {
            margin-bottom: 5.6rem;
            width: 21rem;
            height: 21rem;
          }

          .icon-content {
            width: 100%;
          }
        }

        &--reserve-color {
          // background: linear-gradient(320deg, rgba(255, 255, 255, 0) 0%, #fafafa 100%);
        }

        .text-title {
          margin-bottom: $space-6;
        }

        .text-description {
          padding-left: $space-5;
          color: $text-secondary;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient($logo-primary, rgba($logo-primary, 0));
          }

          span {
            color: $text-hint;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .section-it-work {
    @include container;
    display: flex;
    flex-direction: column;
    gap: $space-10;

    @include responsive(normal) {
      flex-direction: row;
      gap: $space-5;
    }

    .img-it-work {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include responsive(normal) {
        display: block;
      }

      .img-token {
        width: 90%;
        animation: upDown linear infinite;
        animation-duration: 3s;

        @include responsive(tablet) {
          width: 70%;
        }

        &--token-2 {
          width: 45%;
          margin-bottom: $space-10;
          animation-duration: 2s;
          margin-left: -46%;

          @include responsive(tablet) {
            width: 35%;
          }

          @include responsive(normal) {
            margin-left: 0;
          }
        }
      }
    }

    .content {
      display: flex;
      gap: $space-12;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;

      @include responsive(normal) {
        width: 150%;
      }

      .content-title {
        margin-bottom: $space-4;
      }

      .content-text {
        .item-text {
          gap: $space-4;
          display: flex;
          margin-bottom: $space-6;

          &--index {
            width: 3.2rem;
            height: 3.2rem;
            font-weight: 600;
            border-radius: $radius-sm1;
            background-color: $logo-primary;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &--last-item {
            margin-bottom: 0;
          }

          &--content {
            width: calc(100% - 3.2rem - 1.6rem);

            .content-link {
              color: $text-primary;
              transition: 0.3s all;
              text-decoration: underline;

              &:hover {
                color: $logo-primary;
              }
            }
          }
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: $divider-secondary;
      }

      .text-description {
        @include responsive(normal) {
          width: 85%;
        }
      }

      .button-it-work {
        width: 100%;
        font-size: 2.6rem;

        @include responsive(tablet) {
          width: 50%;
        }
      }
    }
  }

  .section-trusted {
    @include container;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      top: 10rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100rem;
      height: 100rem;
      border-radius: 50%;
      background: linear-gradient(0deg, #fff 50%, rgba(235, 235, 235, 0.2) 100%);
      z-index: -2;
    }

    &::before {
      content: "";
      position: absolute;
      top: 21rem;
      left: 50%;
      transform: translateX(-50%);
      width: 78rem;
      height: 78rem;
      border-radius: 50%;
      background: linear-gradient(0deg, #fff 50%, rgba(235, 235, 235, 0.6) 100%);
      z-index: -1;
    }

    .trusted-title {
      text-align: center;
      margin-bottom: $space-20;
    }

    .trusted-project {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: $space-8 $space-3;

      @include responsive(tablet) {
        gap: $space-5;
      }

      @include responsive(normal) {
        justify-content: space-between;
      }

      .item-project {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: $space-5;
        cursor: pointer;
        text-decoration: none;

        &--name {
          color: $text-primary;
          font-size: 2rem;
        }

        .icon-project {
          width: 10rem;
          transition: 0.3s all;

          @include responsive(normal) {
            width: 12rem;
          }
        }

        @include responsive(normal) {
          &:hover {
            .icon-project {
              transform: translateY(-1rem);
            }
          }
        }
      }
    }
  }

  .icon-img {
    position: absolute;
    z-index: -1;
    font-size: 80rem;
    bottom: -32rem;
    left: -18rem;
    color: $text-white;
  }

  .section-faq {
    @include container;
    display: flex;
    gap: $space-10;
    flex-direction: column;

    @include responsive(normal) {
      gap: $space-5;
      flex-direction: row;
    }

    .faq-title {
      width: 100%;
      text-align: center;

      @include responsive(normal) {
        text-align: unset;
        width: calc(100% - 62% - $space-5);
      }
    }

    .faq-question {
      width: 100%;
      border-radius: $radius-lg2;
      background-color: $background-white;

      @include responsive(normal) {
        width: 62%;
      }

      .item-faq {
        border-bottom: 1px solid rgba($divider-secondary, 0.2);

        &:nth-child(2) {
          .item-faq-wrapper {
            max-height: 8.8rem;
            transition: max-height 0.4s;

            @include responsive(normal) {
              max-height: 7.3rem;
            }

            &--expanse {
              max-height: 50rem;
            }
          }
        }

        .item-faq-wrapper {
          overflow: hidden;
          transition: max-height 0.4s;
          // transition: 0.5s all;
          max-height: 7.3rem;

          &--expanse {
            max-height: 50rem;
          }
        }

        &--question {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          padding: $space-6 $space-8;

          .icon-down {
            font-size: 2.4rem;
            transition: 0.3s all;

            &--active {
              transform: rotate(180deg);
            }
          }
        }

        &--answer {
          line-height: 1.4;
          padding: $space-6 $space-8;
          padding-top: 0;
          color: $text-secondary;

          a {
            color: unset;
            transition: 0.3s all;
            text-decoration: underline;
            color: $text-secondary;

            &:hover {
              color: $button-primary;
            }
          }

          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  .section-description {
    @include container;

    .description-title {
      text-align: center;
      margin: 0 auto $space-20;
      width: 100%;

      @include responsive(normal) {
        width: 50%;
      }
    }

    .button-description {
      width: 100%;
      margin: 0 auto;

      @include responsive(tablet) {
        width: 50rem;
      }
    }
  }
}
