@import "styles/main.scss";

.footer-wrapper {
  width: 100%;
  background-color: $background;
  color: $text-white;
  padding: $space-10 0;

  @include responsive(normal) {
    padding: 12rem 0 $space-10;
  }

  .footer-container {
    @include container;
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-10 !important;
    position: relative;
    flex-direction: column;

    @include responsive(normal) {
      flex-direction: row;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100vw;
      height: 1px;
      background-color: $divider-secondary;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .logo {
      display: flex;
      align-items: center;
      gap: $space-4;
      font-size: 4rem;
      font-weight: 500;

      .icon-logo {
        font-size: 7rem;
        color: $logo-primary;
      }
    }

    .box-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: $space-10;
      gap: $space-10;

      @include responsive(normal) {
        margin-top: 0;
        gap: unset;
        width: 70%;
      }

      .item-menu {
        width: 40%;

        @include responsive(normal) {
          width: unset;
        }

        &--title {
          margin-bottom: $space-6 + 0.4rem;
        }

        &--menus {
          a {
            color: $text-white;
            text-decoration: none;
          }

          .menu {
            margin-bottom: $space-5;
            cursor: pointer;
            width: max-content;
            transition: 0.3s all;
            font-size: 2rem;
            position: relative;
            z-index: 0;

            &:last-child {
              margin-bottom: 0;
            }

            &--coming {
              pointer-events: none;
            }

            .coming-soon {
              font-size: 1.2rem;
              padding: $space-1 0.6rem;
              background-color: $background-2;
              border-radius: $radius;

              @include responsive(tablet) {
                font-size: 1.6rem;
              }
            }

            a {
              transition: 0.3s all;
            }

            &:hover {
              a {
                color: $logo-primary;
              }
            }
          }
        }
      }
    }
  }

  .footer-description {
    @include container;
    padding-top: $space-10 !important;

    &--title,
    &--content-box {
      margin-bottom: $space-5;
    }

    &--description,
    &--footer {
      font-size: 1.4rem;
    }

    &--title {
      margin-bottom: $space-2;
    }

    &--content-box {
      font-size: 1.2rem;

      @include responsive(normal) {
        width: 102rem;
      }

      .item-text {
        margin-bottom: $space-2;
        line-height: 1.6;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-weight: 600;
        }

        .list-text {
          padding-left: $space-3;
          margin-top: $space-1;

          &--item {
            display: flex;
            gap: $space-1;
            margin-bottom: $space-1;

            @include responsive(tablet) {
              align-items: center;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .dot {
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background-color: $background-white;
              transform: translateY(0.7rem);

              @include responsive(tablet) {
                transform: translateY(0);
              }
            }
          }
        }

        a {
          text-decoration: underline;
          color: $button-primary;
          cursor: pointer;
          transition: 0.3s all;

          &:hover {
            color: $button-hover;
          }
        }
      }
    }

    &--description {
      margin-bottom: $space-3;
    }

    &--footer {
      color: $text-secondary;
    }
  }
}
